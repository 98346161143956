import React, { useState, useEffect, useMemo } from 'react';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import {
  Box,
  Flex,
  Heading,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Button,
  useColorModeValue,
  Container,
  VStack,
  HStack,
  Text,
  Badge,
  SimpleGrid,
  Stat,
  StatLabel,
  StatNumber,
  StatHelpText,
  Card,
  CardHeader,
  CardBody,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  IconButton,
  Input,
  Select,
  Checkbox,
  Tooltip,
  Image,
} from '@chakra-ui/react';
import { DragHandleIcon, ChevronDownIcon, SearchIcon, ArrowUpDownIcon } from '@chakra-ui/icons';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { useTable, useSortBy, useGlobalFilter, usePagination } from 'react-table';
import { FaDesktop, FaMobileAlt, FaTabletAlt } from 'react-icons/fa';

function Dashboard() {
  const [domains, setDomains] = useState([]);
  const [clicks, setClicks] = useState([]);
  const navigate = useNavigate();

  // Move all useColorModeValue calls to the top level
  const bg = useColorModeValue('white', 'navy.800');
  const textColor = useColorModeValue('gray.700', 'white');
  const borderColor = useColorModeValue('gray.200', 'whiteAlpha.100');
  const tableHeaderBg = useColorModeValue('gray.50', 'whiteAlpha.100');
  const hoverBg = useColorModeValue('gray.50', 'gray.800');

  useEffect(() => {
    const fetchData = async () => {
      const token = localStorage.getItem('token');
      const config = { headers: { 'x-access-token': token } };
      const domainsResponse = await axios.get('https://apiagg.qctcoin.com/domains', config);
      const clicksResponse = await axios.get('https://apiagg.qctcoin.com/clicks', config);
      setDomains(domainsResponse.data);
      setClicks(clicksResponse.data);
    };
    fetchData();
  }, []);

  const handleLogout = () => {
    localStorage.removeItem('token');
    navigate('/login');
  };

  const onDragEnd = (result) => {
    if (!result.destination) return;
    const items = Array.from(domains);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    setDomains(items);
  };

  const columns = useMemo(
    () => [
      {
        Header: 'Domain',
        accessor: 'domain.domain',
      },
      {
        Header: 'Timestamp',
        accessor: 'timestamp',
        Cell: ({ value }) => new Date(value).toLocaleString(),
      },
      {
        Header: 'IP Address',
        accessor: 'ipAddress',
      },
      {
        Header: 'Country',
        accessor: 'location.country',
        Cell: ({ value }) => (
          <Flex alignItems="center">
            
            <Text>{value}</Text>
          </Flex>
        ),
      },
      {
        Header: 'User Agent',
        accessor: 'userAgent',
        Cell: ({ value }) => (
          <Text>{value}</Text>
        ),
      },
    ],
    []
  );

  const data = useMemo(() => clicks, [clicks]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize, globalFilter },
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: 5 },
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  return (
    <Box minH="100vh" bg={useColorModeValue('gray.100', 'gray.900')}>
      <Container maxW="container.xl" py={5}>
        <VStack spacing={8} align="stretch">
          <Flex justifyContent="space-between" alignItems="center">
            <Heading size="xl" color={textColor}>Admin Dashboard</Heading>
            <HStack>
              <Button as={Link} to="/configure-domains" colorScheme="blue" variant="outline">
                Configure Domains
              </Button>
              <Button onClick={handleLogout} colorScheme="red" variant="outline">
                Logout
              </Button>
            </HStack>
          </Flex>

          <SimpleGrid columns={{ base: 1, md: 3 }} spacing={6}>
            <Stat bg={bg} shadow="xl" border="1px solid" borderColor={borderColor} borderRadius="lg" p={5}>
              <StatLabel color={textColor}>Total Domains</StatLabel>
              <StatNumber color={textColor}>{domains.length}</StatNumber>
              <StatHelpText>
                Registered domains
              </StatHelpText>
            </Stat>
            <Stat bg={bg} shadow="xl" border="1px solid" borderColor={borderColor} borderRadius="lg" p={5}>
              <StatLabel color={textColor}>Total Clicks</StatLabel>
              <StatNumber color={textColor}>{clicks.length}</StatNumber>
              <StatHelpText>
                All-time clicks
              </StatHelpText>
            </Stat>
            <Stat bg={bg} shadow="xl" border="1px solid" borderColor={borderColor} borderRadius="lg" p={5}>
              <StatLabel color={textColor}>Active Users</StatLabel>
              <StatNumber color={textColor}>1</StatNumber>
              <StatHelpText>
                Current admin users
              </StatHelpText>
            </Stat>
          </SimpleGrid>

          <Card>
            <CardHeader>
              <Heading size="lg" color={textColor}>Domains</Heading>
            </CardHeader>
            <CardBody>
              <Box overflowX="auto">
                <Table variant="simple">
                  <Thead>
                    <Tr bg={tableHeaderBg}>
                      <Th color={textColor}>Domain</Th>
                      <Th color={textColor}>OnlyFans Profile</Th>
                      <Th color={textColor}>Actions</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {domains.map((domain) => (
                      <Tr key={domain._id}>
                        <Td color={textColor}>{domain.domain}</Td>
                        <Td color={textColor}>{domain.onlyfansProfile}</Td>
                        <Td>
                          <Menu>
                            <MenuButton
                              as={IconButton}
                              icon={<ChevronDownIcon />}
                              variant="outline"
                              size="sm"
                            />
                            <MenuList>
                              <MenuItem onClick={() => navigate(`/configure-domains`)}>Configure Domain</MenuItem>
                            </MenuList>
                          </Menu>
                        </Td>
                      </Tr>
                    ))}
                  </Tbody>
                </Table>
              </Box>
            </CardBody>
          </Card>

          <Card>
            <CardHeader>
              <Flex justifyContent="space-between" alignItems="center">
                <Heading size="lg" color={textColor}>Recent Clicks</Heading>
                <Flex>
                  <Input
                    placeholder="Search"
                    onChange={(e) => setGlobalFilter(e.target.value)}
                    mr={2}
                  />
                  <Select
                    value={pageSize}
                    onChange={(e) => setPageSize(Number(e.target.value))}
                  >
                    {[5, 10, 20, 30, 40, 50].map((pageSize) => (
                      <option key={pageSize} value={pageSize}>
                        Show {pageSize}
                      </option>
                    ))}
                  </Select>
                </Flex>
              </Flex>
            </CardHeader>
            <CardBody>
              <Box overflowX="auto">
                <Table {...getTableProps()} variant="simple">
                  <Thead>
                    {headerGroups.map((headerGroup) => (
                      <Tr {...headerGroup.getHeaderGroupProps()} bg={tableHeaderBg}>
                        {headerGroup.headers.map((column) => (
                          <Th
                            {...column.getHeaderProps(column.getSortByToggleProps())}
                            color={textColor}
                          >
                            <Flex alignItems="center">
                              {column.render('Header')}
                              {column.isSorted ? (
                                column.isSortedDesc ? (
                                  <ArrowUpDownIcon ml={1} />
                                ) : (
                                  <ArrowUpDownIcon ml={1} />
                                )
                              ) : (
                                <ArrowUpDownIcon ml={1} opacity={0.4} />
                              )}
                            </Flex>
                          </Th>
                        ))}
                      </Tr>
                    ))}
                  </Thead>
                  <Tbody {...getTableBodyProps()}>
                    {page.map((row) => {
                      prepareRow(row);
                      return (
                        <Tr {...row.getRowProps()} _hover={{ bg: hoverBg }}>
                          {row.cells.map((cell) => (
                            <Td {...cell.getCellProps()} color={textColor}>
                              {cell.render('Cell')}
                            </Td>
                          ))}
                        </Tr>
                      );
                    })}
                  </Tbody>
                </Table>
              </Box>
              <Flex justifyContent="space-between" m={4} alignItems="center">
                <Flex>
                  <Tooltip label="First Page">
                    <IconButton
                      onClick={() => gotoPage(0)}
                      isDisabled={!canPreviousPage}
                      icon={<ArrowUpDownIcon />}
                      mr={4}
                    />
                  </Tooltip>
                  <Tooltip label="Previous Page">
                    <IconButton
                      onClick={previousPage}
                      isDisabled={!canPreviousPage}
                      icon={<ArrowUpDownIcon />}
                    />
                  </Tooltip>
                </Flex>

                <Flex alignItems="center">
                  <Text flexShrink="0" mr={8}>
                    Page{' '}
                    <Text fontWeight="bold" as="span">
                      {pageIndex + 1}
                    </Text>{' '}
                    of{' '}
                    <Text fontWeight="bold" as="span">
                      {pageOptions.length}
                    </Text>
                  </Text>
                  <Text flexShrink="0">Go to page:</Text>{' '}
                  <Input
                    type="number"
                    defaultValue={pageIndex + 1}
                    onChange={(e) => {
                      const page = e.target.value ? Number(e.target.value) - 1 : 0;
                      gotoPage(page);
                    }}
                    w={28}
                    ml={2}
                  />
                </Flex>

                <Flex>
                  <Tooltip label="Next Page">
                    <IconButton
                      onClick={nextPage}
                      isDisabled={!canNextPage}
                      icon={<ArrowUpDownIcon />}
                    />
                  </Tooltip>
                  <Tooltip label="Last Page">
                    <IconButton
                      onClick={() => gotoPage(pageCount - 1)}
                      isDisabled={!canNextPage}
                      icon={<ArrowUpDownIcon />}
                      ml={4}
                    />
                  </Tooltip>
                </Flex>
              </Flex>
            </CardBody>
          </Card>
        </VStack>
      </Container>
    </Box>
  );
}

export default Dashboard;