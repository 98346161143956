import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

function AddDomain() {
  const [domain, setDomain] = useState('');
  const [onlyfansProfile, setOnlyfansProfile] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const token = localStorage.getItem('token');
      const config = { headers: { 'x-access-token': token } };
      await axios.post('https://apiagg.qctcoin.com/domains', { domain, onlyfansProfile }, config);
      navigate('/dashboard');
    } catch (error) {
      alert('Error adding domain');
    }
  };

  return (
    <div>
      <h1>Add New Domain</h1>
      <form onSubmit={handleSubmit}>
        <input
          type="text"
          value={domain}
          onChange={(e) => setDomain(e.target.value)}
          placeholder="Domain"
          required
        />
        <input
          type="text"
          value={onlyfansProfile}
          onChange={(e) => setOnlyfansProfile(e.target.value)}
          placeholder="OnlyFans Profile"
          required
        />
        <button type="submit">Add Domain</button>
      </form>
    </div>
  );
}

export default AddDomain;
