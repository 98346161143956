import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Button,
  Container,
  Heading,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Input,
  VStack,
  useToast,
  HStack,
} from '@chakra-ui/react';

function ConfigureDomains() {
  const [domains, setDomains] = useState([]);
  const [editedDomains, setEditedDomains] = useState({});
  const navigate = useNavigate();
  const toast = useToast();

  useEffect(() => {
    fetchDomains();
  }, []);

  const fetchDomains = async () => {
    try {
      const token = localStorage.getItem('token');
      const config = { headers: { 'x-access-token': token } };
      const response = await axios.get('https://apiagg.qctcoin.com/domains', config);
      setDomains(response.data);
    } catch (error) {
      console.error('Error fetching domains:', error);
      toast({
        title: 'Error fetching domains',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleUpdateDomain = async (id) => {
    try {
      const token = localStorage.getItem('token');
      const config = { headers: { 'x-access-token': token } };
      await axios.put(`https://apiagg.qctcoin.com/domains/${id}`, { onlyfansProfile: editedDomains[id] }, config);
      fetchDomains();
      toast({
        title: 'Domain updated successfully',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
      setEditedDomains(prev => ({ ...prev, [id]: undefined }));
    } catch (error) {
      console.error('Error updating domain:', error);
      toast({
        title: 'Error updating domain',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleInputChange = (id, value) => {
    setEditedDomains(prev => ({ ...prev, [id]: value }));
  };

  return (
    <Container maxW="container.xl" py={5}>
      <VStack spacing={8} align="stretch">
        <Heading size="xl">Configure Domains</Heading>

        <Box>
          <Heading size="md" mb={4}>Existing Domains</Heading>
          <Table variant="simple">
            <Thead>
              <Tr>
                <Th>Domain</Th>
                <Th>Redirect URL</Th>
                <Th>Action</Th>
              </Tr>
            </Thead>
            <Tbody>
              {domains.map((domain) => (
                <Tr key={domain._id}>
                  <Td>{domain.domain}</Td>
                  <Td>
                    <Input
                      defaultValue={domain.onlyfansProfile}
                      onChange={(e) => handleInputChange(domain._id, e.target.value)}
                    />
                  </Td>
                  <Td>
                    <Button
                      colorScheme="blue"
                      onClick={() => handleUpdateDomain(domain._id)}
                      isDisabled={!editedDomains[domain._id]}
                    >
                      Save
                    </Button>
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </Box>

        <Button onClick={() => navigate('/dashboard')}>Back to Dashboard</Button>
      </VStack>
    </Container>
  );
}

export default ConfigureDomains;
